<template>
  <v-card
    flat
    @click="edit"
    class="w-100 d-flex justify-space-between align-center pa-6 px-sm-0 py-sm-3"
  >
    <v-row
      no-gutters
      justify="space-between"
      justify-sm="center"
      :justify-md="isCheckout ? 'space-between' : 'start'"
      align="center"
    >
      <div class="d-flex flex-row align-center">
        <v-icon x-large color="primary" class="mr-2">{{ icon }}</v-icon>
        <div class="text-body-2">
          <div class="text text-uppercase font-weight-bold">{{ text }}</div>
          <div class="value">{{ infoValue }}</div>
        </div>
      </div>
      <v-icon color="default" right v-if="editable">
        $edit
      </v-icon>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: true },
    text: { type: String, required: true },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true },
    isCheckout: { type: Boolean, default: false }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
