<template>
  <div>
    <div class="text-body-2 text-uppercase font-weight-bold">
      {{ $t("checkout.giftCodeTitle") }}
    </div>

    <div class="d-flex align-center">
      <v-text-field
        class="add-code rounded-xl white apply mt-2 mr-3 mr-sm-0"
        clearable
        filled
        solo
        hide-details
        :dense="$vuetify.breakpoint.xs"
        :label="$t('checkout.giftCodeFieldLabel')"
        :height="$vuetify.breakpoint.xs ? 'auto' : '50'"
        v-model="giftCode"
      >
        <template v-slot:append v-if="!$vuetify.breakpoint.xs">
          <v-btn
            elevation="2"
            medium
            class="rounded-xl"
            height="56"
            width="91"
            v-on:click="sendGiftCode(giftCode)"
            :disabled="!giftCode"
            color="primary"
            ><template slot:label>
              <span class="font-weight-bold">{{
                $t("checkout.giftCodeApply")
              }}</span>
            </template></v-btn
          >
        </template></v-text-field
      >
      <!-- v-btn for mobile layout -->
      <v-btn
        elevation="2"
        medium
        height="42"
        class="mt-3 d-flex d-sm-none rounded-xl"
        v-on:click="sendGiftCode(giftCode)"
        :disabled="!giftCode"
        color="primary"
        >{{ $t("checkout.giftCodeApply") }}</v-btn
      >
    </div>
  </div>
</template>

<style lang="scss">
.add-code .v-input__slot {
  box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 0px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.add-code .v-btn span {
  font-size: 14px !important;
}
.apply {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .v-input__slot {
      padding-right: 0 !important;
    }
  }
}
.certificate {
  border-bottom: 1px solid #707070;
}
</style>

<script>
import { mapActions } from "vuex";
// import GiftRow from "./GiftRow.vue";
export default {
  // components: { GiftRow },
  name: "GiftCode",
  props: { giftCertificates: { type: Array, required: true } },
  data() {
    return {
      giftCode: ""
    };
  },
  methods: {
    ...mapActions({
      addGiftCode: "cart/sendGiftCode"
    }),
    async sendGiftCode(gift) {
      await this.addGiftCode(gift);
      this.giftCode = "";
      this.$emit("add");
    }
  }
};
</script>
