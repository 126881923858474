<template>
  <v-card
    flat
    @click="clicked"
    class="newsletter primary lighten-1 white--text pa-3 pa-sm-13"
  >
    <div class="text-h4 text-sm-h3 font-weight-bold">
      {{ $t("newsletter.title") }}
    </div>
    <div class="text--white mr-md-4">
      {{ $t("newsletter.subtitle") }}
    </div>
    <v-card-actions>
      <v-btn
        elevation="0"
        class="mt-5 primary--text font-weight-bold"
        :block="$vuetify.breakpoint.xs"
        @click="openModal"
        :x-large="$vuetify.breakpoint.xs"
      >
        {{ $t("newsletter.button") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.newsletter {
  background-image: url("/img_layout/newsletter-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  max-height: 214px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    background-position: unset;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import login from "~/mixins/login";
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";

export default {
  name: "NewsletterHorizontal",
  props: ["links"],
  mixins: [clickHandler, login],
  data() {
    return { modalOpen: false, dialogInstance: null };
  },
  methods: {
    async openModal() {
      this.dialogInstance = await this.$dialog.show(NinjaForm, {
        formId: 2,
        options: {
          dense: true,
          outlined: false,
          filled: true,
          solo: true
        }
      });
      // this.dialogInstance = await this.$dialog.show(FormNewsletter, {
      //   width: "1100px"
      // });
      const result = await this.dialogInstance.wait();
      if (result) {
        this.$emit("submit", true);
      }
    }
  }
};
</script>
