<template>
  <div v-if="packages && packages.length">
    <div class="text-body-2 font-weight-bold text-uppercase">
      {{ $t("checkout.packageAddTitle") }}
    </div>
    <div class="text-body-2 py-3" v-html="$t('checkout.packageAddDescr')"></div>

    <v-radio-group class="w-100" mandatory v-model="packagingTypeId" row>
      <v-radio
        v-for="(pck, idx) in packages"
        :key="pck.productId"
        :value="'' + pck.productId"
        color="default"
        class="w-45"
        :class="{
          'w-100': $vuetify.breakpoint.xs,
          'mb-8': $vuetify.breakpoint.xs && idx === 0
        }"
      >
        <template v-slot:label>
          <img
            :src="`/img_layout/packaging_type/${pck.productId}.svg`"
            :alt="pck.name"
          />
          <span class="ml-2">{{ pck.name }}</span>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import cartService from "~/service/cartService";
import { mapCartInfo } from "~/service/ebsn";
export default {
  name: "PackagesTypeList",
  data() {
    return { packages: [] };
  },
  computed: {
    ...mapCartInfo({
      packagingTypeId: "package_type"
    })
  },
  methods: {
    async fetchPackages() {
      this.packages = await cartService.getPackages();
    }
  },
  mounted() {
    this.fetchPackages();
  }
};
</script>
