<template>
  <div>
    <v-container v-if="headerProposals.length > 0">
      <component
        v-if="headerProposals.length > 0"
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
    <PaymentStepper :step="1" />

    <v-container class="checkout-title">
      <CategoryTitle :category="category" :showOnMobile="true" />
    </v-container>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12" md="8" class="pt-0">
          <v-row no-gutters>
            <div
              class="d-flex w-100 flex-column flex-sm-row justify-space-between px-3 pt-0 py-sm-5 mb-4 mb-sm-0"
            >
              <CartInfoAddressCard
                :shippingAddress="cart.shippingAddress"
                :editable="editableAddress"
                @edit="openAddressSelector"
                :isCheckout="true"
              />
              <v-divider
                :vertical="!$vuetify.breakpoint.xs"
                class="grey mx-8 mx-md-4 mx-lg-8"
              />
              <CartInfoTimeslotCard
                :shippingAddress="cart.shippingAddress"
                :timeslot="cart.timeslot"
                :editable="editableTimeslot"
                @edit="openTimeslotSelector"
                :isCheckout="true"
              />
            </div>
          </v-row>
          <GiftCode
            v-if="!get(category, 'metaData.template_model.HIDE_GIFT')"
            :giftCertificates="giftCertificates"
            @add="loadGiftCertificates"
            @remove="loadGiftCertificates"
          />
          <gift-list
            v-if="giftCertificates.length > 0"
            :mode="'checkout'"
            :title="$t('checkout.giftCodeSubtitle')"
            :giftList="giftCertificates"
            @reload="loadGiftCertificates"
          />
          <PackagesTypeList class="my-7" />
          <v-checkbox
            v-model="accept"
            true-value="1"
            false-value="0"
            class="mt-0 mt-sm-12 ma-0 mb-2 privacy-check"
            hide-details
            required
          >
            <template v-slot:label>
              <div class="d-flex flex-column default--text">
                <span class="text-h5 font-weight-bold">{{
                  $t("checkout.acceptTitle")
                }}</span>
                <i18n path="checkout.acceptTextPre" tag="div">
                  <template v-slot:link>
                    <router-link :to="'/page/regolamento'">
                      <span class="text-body-2">
                        {{ $t("checkout.acceptTextLink") }}</span
                      >
                    </router-link>
                  </template>
                </i18n>
              </div>
            </template>
          </v-checkbox>

          <v-alert
            v-if="error"
            type="error"
            border="left"
            transition="v-fab-transition"
          >
            {{ error }}
          </v-alert>
          <div class="payment-type d-none d-sm-flex justify-center">
            <v-btn
              color="primary"
              class="mt-5 d-block"
              x-large
              depressed
              :disabled="
                profileLevel < minProfileLevel ||
                  cart.totalItems == 0 ||
                  !accept ||
                  accept === '0'
              "
              :loading="loading"
              @click="goToPayment"
            >
              <span>{{ $t("checkout.goToPaymentBtn") }}</span>
            </v-btn>
          </div>
          <component
            v-if="footerProposals.length > 0"
            :is="footerMode"
            :title="footerTitle"
            :proposals="footerProposals"
            paginationClass="pagination-footer"
            :cols="1"
            :sm="2"
            :md="3"
            :lg="3"
          />
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <CheckoutCompleteProfile v-if="profileLevel < minProfileLevel" />
          <CheckoutConfirmEmail
            v-if="!cart.user.profile.confirmed"
            :key="cart.refreshTime"
          />

          <CheckoutSummary
            :orderCart="cart"
            @removeGiftCode="loadGiftCertificates"
          />

          <v-btn
            class="d-flex d-md-none mt-5 mx-auto"
            color="primary"
            x-large
            :block="$vuetify.breakpoint.xs"
            depressed
            :disabled="
              profileLevel < minProfileLevel ||
                cart.totalItems == 0 ||
                !accept ||
                accept === '0'
            "
            :loading="loading"
            @click="goToPayment"
          >
            <span>{{ $t("checkout.goToPaymentBtn") }}</span>
          </v-btn>
          <component
            v-if="sideboxProposals.length > 0"
            :is="sideboxMode"
            :title="sideboxTitle"
            :proposals="sideboxProposals"
            paginationClass="pagination-sidebox"
            :cols="1"
            :sm="1"
            :md="1"
            :lg="1"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <NewsletterHorizontal class="mt-6 mt-sm-10" />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.privacy-check {
  ::v-deep a {
    color: var(--v-default-base) !important;
    font-weight: bold;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .checkout-title {
      ::v-deep h1 {
        font-size: 24px !important;
      }
    }
  }
}
.qty-wrap {
  max-width: 160px;
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CheckoutConfirmEmail from "@/components/cart/CheckoutConfirmEmail.vue";
import GiftCode from "@/components/gift/GiftCode.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";

import AnalyticsService from "~/service/analyticsService";
import RegistrationService from "~/service/userService";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState, mapGetters } from "vuex";
import { mapCartInfo } from "~/service/ebsn";
import { mapProposalComponent } from "~/service/ebsn";

// import forEach from "lodash/forEach";
// import cloneDeep from "lodash/cloneDeep";

import get from "lodash/get";
import GiftList from "@/components/gift/GiftList.vue";
export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    GiftCode,
    CheckoutCompleteProfile,
    CheckoutConfirmEmail,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    PackagesTypeList,
    PaymentStepper,
    NewsletterHorizontal,
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalProductGrid.vue"),
    GiftList
  },
  data() {
    return {
      error: null,
      loading: false,
      minProfileLevel: global.config.minProfileLevel,
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {}
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel"
    }),
    ...mapProposalComponent({
      header: "header",
      footer: "footer",
      sidebox: "sidebox"
    }),
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    ...mapCartInfo({
      accept: "accept_rules"
    })
  },

  methods: {
    get: get,
    ...mapActions({
      getCart: "cart/getCart",
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list"
      );
      var manualGiftCertificates = await RegistrationService.listGiftCertificate(
        "manual"
      );

      this.giftCertificates = [
        ...giftCertificates,
        ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return !!giftCertificate.giftCode || hasUserGift.length > 0;
      });
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Payment"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        await this.loadGiftCertificates();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    }
  },
  mounted() {
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
  },
  created() {
    AnalyticsService.beginCheckout(this.cart);
    this.loadGiftCertificates();
  }
};
</script>
